@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.u-taL {
  text-align: left !important; }

.u-taC {
  text-align: center !important; }
  @media screen and (max-width: 568px) {
    .u-taC--xs-taL {
      text-align: left !important; } }
  @media screen and (max-width: 768px) {
    .u-taC--sm-taL {
      text-align: left !important; } }
  @media screen and (max-width: 1024px) {
    .u-taC--md-taL {
      text-align: left !important; } }
  @media screen and (max-width: 1280px) {
    .u-taC--lg-taL {
      text-align: left !important; } }

.u-taR {
  text-align: right !important; }

.u-only-xs {
  display: none !important; }
  @media screen and (max-width: 568px) {
    .u-only-xs {
      display: block !important; } }

.u-only-sm {
  display: none !important; }
  @media screen and (max-width: 768px) {
    .u-only-sm {
      display: block !important; } }
  @media screen and (max-width: 568px) {
    .u-only-sm {
      display: none !important; } }

.u-only-md {
  display: none !important; }
  @media screen and (max-width: 1024px) {
    .u-only-md {
      display: block !important; } }
  @media screen and (max-width: 768px) {
    .u-only-md {
      display: none !important; } }

@media screen and (max-width: 1024px) {
  .u-only-lg {
    display: none !important; } }

.u-under-sm {
  display: none !important; }
  @media screen and (max-width: 768px) {
    .u-under-sm {
      display: block !important; } }

.u-not-under-sm {
  display: block !important; }
  @media screen and (max-width: 768px) {
    .u-not-under-sm {
      display: none !important; } }

.u-overflow-hidden {
  overflow: hidden !important; }

@keyframes slideAppear {
  from {
    opacity: 0;
    transform: scale(0);
    filter: blur(100px); }
  to {
    opacity: 1;
    transform: scale(1);
    filter: blur(0px); } }

#top .p-slider {
  border-radius: 10px;
  overflow: hidden;
  position: absolute; }
  #top .p-slider .slick-list,
  #top .p-slider .slick-track,
  #top .p-slider .slick-slide,
  #top .p-slider div {
    width: 100%;
    height: 100%; }
  #top .p-slider__item {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover; }
    #top .p-slider__item img {
      width: 100%;
      height: auto; }
  #top .p-slider--01 {
    top: 150px;
    left: 55%;
    width: 230px;
    height: 270px;
    z-index: 1;
    opacity: 0;
    animation: slideAppear 1.5s ease .5s;
    animation-fill-mode: forwards; }
    @media screen and (max-width: 834px) {
      #top .p-slider--01 {
        display: none; } }
    @media screen and (max-width: 768px) {
      #top .p-slider--01 {
        top: 120px;
        left: 51%;
        width: 200px;
        height: 200px; } }
    @media screen and (max-width: 568px) {
      #top .p-slider--01 {
        display: none; } }
  #top .p-slider--02 {
    top: 300px;
    left: 65%;
    width: 275px;
    height: 320px;
    z-index: 0;
    opacity: 0;
    animation: slideAppear 1.5s ease 1s;
    animation-fill-mode: forwards; }
    @media screen and (max-width: 834px) {
      #top .p-slider--02 {
        top: 200px; } }
    @media screen and (max-width: 768px) {
      #top .p-slider--02 {
        top: 400px;
        left: 15px;
        width: 400px;
        height: 350px; } }
    @media screen and (max-width: 568px) {
      #top .p-slider--02 {
        top: initial;
        bottom: 0px;
        right: 15px;
        left: initial;
        width: 300px;
        height: 250px; } }
    @media screen and (max-width: 500px) {
      #top .p-slider--02 {
        bottom: -20px; } }
  #top .p-slider--03 {
    bottom: 150px;
    left: 52%;
    width: 180px;
    height: 160px;
    z-index: 0;
    opacity: 0;
    animation: slideAppear 1.5s ease 1.5s;
    animation-fill-mode: forwards; }
    @media screen and (max-width: 768px) {
      #top .p-slider--03 {
        top: 350px;
        left: initial;
        right: 15px;
        width: 50vw;
        height: 200px; } }
    @media screen and (max-width: 568px) {
      #top .p-slider--03 {
        width: 40vw;
        left: 15px; } }
    @media screen and (max-width: 500px) {
      #top .p-slider--03 {
        top: 370px; } }
    @media screen and (max-width: 350px) {
      #top .p-slider--03 {
        display: none; } }
  #top .p-slider--04 {
    bottom: 150px;
    left: 0%;
    width: 300px;
    height: 240px;
    z-index: 0;
    opacity: 0;
    animation: slideAppear 1.5s ease 2s;
    animation-fill-mode: forwards; }
    @media screen and (max-width: 1500px) {
      #top .p-slider--04 {
        display: none; } }

#top #wrapper {
  padding: 0 0; }
  @media screen and (max-width: 1200px) {
    #top #wrapper {
      padding: 60px 0 0; } }

#top .p-mainVisual {
  width: 100%;
  height: 800px;
  background-image: url("/inc/image/top/bg_mainVisual.jpg.webp");
  background-size: 100% 100%;
  position: relative;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 768px) {
    #top .p-mainVisual {
      align-items: flex-start; } }
  @media screen and (max-width: 568px) {
    #top .p-mainVisual {
      height: 650px;
      padding-bottom: 50px; } }
  @media screen and (max-width: 768px) {
    #top .p-mainVisual .l-section {
      margin-top: calc(104px + 30px); } }
  #top .p-mainVisual__catch {
    max-width: 400px;
    margin-left: 100px; }
    @media screen and (max-width: 1280px) {
      #top .p-mainVisual__catch {
        margin-left: 50px; } }
    @media screen and (max-width: 1024px) {
      #top .p-mainVisual__catch {
        margin-left: 0px; } }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual__catch {
        width: 100%;
        max-width: initial;
        margin-left: 10px; } }
    @media screen and (max-width: 568px) {
      #top .p-mainVisual__catch {
        margin-left: 0; } }
    #top .p-mainVisual__catch h2 {
      font-size: 40px; }
      @media screen and (max-width: 1024px) {
        #top .p-mainVisual__catch h2 {
          font-size: 30px; } }

@media screen and (max-width: 568px) {
  #top #newslist {
    margin-top: 100px; } }

@media screen and (max-width: 1024px) {
  #top .p-service {
    margin-top: 50px; } }

@media screen and (max-width: 768px) {
  #top .p-service {
    margin-top: 200px; } }

#top .p-service__img {
  height: 100%; }
  #top .p-service__img img {
    height: 100%;
    object-fit: cover; }

#top .p-service__item > .c-btn::before {
  content: '・'; }

#top .p-service__list {
  margin-bottom: 60px; }
  @media screen and (max-width: 568px) {
    #top .p-service__list {
      margin-bottom: 30px; } }

#top .p-service__text {
  padding: 100px; }
  @media screen and (max-width: 1024px) {
    #top .p-service__text {
      padding: 50px; } }
  @media screen and (max-width: 768px) {
    #top .p-service__text {
      padding: 10px 15px; } }

#top .p-btn {
  margin-bottom: 0px; }

#top .p-btnList__link {
  display: block;
  text-align: center;
  padding: 40px 200px;
  border-radius: 10px;
  overflow: hidden;
  color: #333333;
  font-size: 22px;
  font-weight: bold;
  box-shadow: 0px 2px 5px #CCCCCC; }
  @media screen and (max-width: 1024px) {
    #top .p-btnList__link {
      padding: 40px 100px; } }
  @media screen and (max-width: 768px) {
    #top .p-btnList__link {
      font-size: 18px;
      padding: 20px 50px; } }
  #top .p-btnList__link small {
    display: block;
    font-size: 12px; }
  #top .p-btnList__link--product {
    background-image: url("/inc/image/top/bg_btn-product.png"); }
  #top .p-btnList__link--recruit {
    background-image: url("/inc/image/top/bg_btn-recruit.png"); }

#top .p-case__heading {
  padding-bottom: 20px;
  margin-bottom: 0px; }

#top .p-case__btn {
  padding-top: 30px; }

#top .p-caseList {
  display: flex;
  justify-content: center;
  object-fit: cover;
  padding: 10px 0;
  overflow: hidden; }

@keyframes infinity-scroll {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(-200%); } }

#top .p-card02 {
  display: flex;
  flex-wrap: nowrap;
  animation: infinity-scroll 30s infinite linear 0.5s both; }
  #top .p-card02__item {
    padding: 0 10px;
    width: calc(100vw/4); }
    @media screen and (max-width: 1024px) {
      #top .p-card02__item {
        width: calc(100vw/3); } }
    @media screen and (max-width: 768px) {
      #top .p-card02__item {
        width: calc(100vw/2); } }
    @media screen and (max-width: 568px) {
      #top .p-card02__item {
        width: calc(100vw/1); } }
    #top .p-card02__item .c-card02__thumb {
      overflow: hidden; }
      #top .p-card02__item .c-card02__thumb img {
        width: 100%; }
